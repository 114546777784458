import axios from "./base";

export function getAllStores() {
    return axios.get(`store/all/`);
}

export function createStore(store_name, store_phone) {
    return axios.post(`store/add/`, {
        name: store_name,
        phone_number: store_phone
    });
}

export function getStoreByFields(store_name, store_phone) {
    return axios.post(`store/get-by-fields/`, {
        name: store_name,
        phone_number: store_phone
    });
}