import styles from "./DesktopHeader.module.css";
import React, { useEffect, useState } from "react";
import { FaRegBell } from "@react-icons/all-files/fa/FaRegBell";
import { useNavigate } from "react-router-dom";
import { FiX } from "@react-icons/all-files/fi/FiX";

import { getInquiryEventsCount } from "../../APIs/inquiry";

const DesktopHeader = ({ role, handleNotificationPanelToggle, showNotification = true }) => {
    const navigate = useNavigate();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
    let pageLink;
    switch (role.toLowerCase()) {
        case "financialmanager":
            pageLink = "financial-manager";
            break;
        case "financial-manager":
            pageLink = "financial-manager";
            break;
        case "client":
            pageLink = "user";
            break;
        case "user":
            pageLink = "user";
            break;
        case "auditor":
            pageLink = "auditor";
            break;
        case "manager":
            pageLink = "manager";
            break;
        default:
            pageLink = "login";
    }
    const [inquiryEventsCount, setInquiryEventsCount] = useState(0);

    useEffect(() => {
        const fetchInquiryEventsCount = async () => {
            try {
                const inquiryEventCountData = await getInquiryEventsCount();
                setInquiryEventsCount(inquiryEventCountData.data.count);
            } catch (error) {
                setInquiryEventsCount(0);
            } finally {
            }
        };

        if (showNotification) {
            fetchInquiryEventsCount();
        }

    }, [isNotificationPanelOpen]);

    const toggleNotificationPanel = () => {
        setIsNotificationPanelOpen(!isNotificationPanelOpen);
        handleNotificationPanelToggle();
    };

    return (
        <div className={styles.HeaderContainer}>
            <div
                className={isNotificationPanelOpen ? styles.rightSectionNotificationPanelOpen : styles.rightSection}>
                <a href={'/'} className={styles.platformName}>ایران‌تدارکات</a>
            </div>
            <div className={isNotificationPanelOpen ? styles.MenuNotificationPanelOpen : styles.Menu}>
                <span className={styles.MenuItem} onClick={() => navigate(`/${pageLink}`)}>صفحه‌ اصلی</span>
                <span className={styles.MenuItem} onClick={() => navigate(`/${pageLink}`)}>درخواست‌ها</span>
                <span className={styles.MenuItem} onClick={() => navigate(`/${pageLink}/dashboard/`)}>گزارش‌ها</span>
                <span className={styles.MenuItem} onClick={() => navigate(`/coming-soon`)}>آموزش</span>
                <span className={styles.MenuItem} onClick={() => navigate('/logout')}>خروج</span>
            </div>
            {showNotification ?
                isNotificationPanelOpen ? (
                    <div className={styles.NotificationSectionHeader}>
                        <FiX className={styles.BellIconOpened} onClick={toggleNotificationPanel} />
                        <span className={styles.NotificationsText}>اطلاعیه‌ها</span>
                    </div>
                ) :
                    <div className={styles.BellIconContainer}>
                        <FaRegBell className={styles.BellIcon} onClick={toggleNotificationPanel} />
                        {
                            inquiryEventsCount !== 0 ? (
                                inquiryEventsCount < 10 ? (
                                    <span className={styles.NotificationCount}>{inquiryEventsCount}</span>
                                ) : (
                                    <span className={styles.NotificationCount}>۹+</span>
                                )
                            ) : (
                                <></>
                            )
                        }
                    </div>
                : null
            }
        </div>
    );
}


export default DesktopHeader;