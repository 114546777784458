import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from "../../../styles/auditor/desktop/SelectProductModal.module.css";
import CreatableSelect from "react-select/creatable";
import {
    addProduct,
    getBrands,
    getMainCategories,
    getProductAttributeKeys,
    getProductAttributeValues,
    getProductByFields,
    getProductById,
    getSubCategories,
    searchProducts,
    updateProduct
} from "../../../APIs/product";
import LoadingComponent from "../../../components/Loading/Loading";
import { creatableSelectCustomStyles } from "../../common/utils";


const SelectProduct = forwardRef(({ initiallySelectedProduct, addProductToReceipt }, ref) => {
    const [loading, setLoading] = useState(false);
    const [selectedProductMainCategory, setSelectedProductMainCategory] = useState(null);
    const [selectedProductSubCategory, setSelectedProductSubCategory] = useState(null);
    const [selectedProductBrand, setSelectedProductBrand] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedAttributesList, setSelectedAttributesList] = useState([]);

    const [showMainCategoryError, setShowMainCategoryError] = useState(false);
    const [showSubCategoryError, setShowSubCategoryError] = useState(false);
    const [showBrandError, setShowBrandError] = useState(false);
    const [showProductError, setShowProductError] = useState(false);
    const [attributeErrors, setAttributeErrors] = useState([]);


    const [mainCategories, setMainCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [productsName, setProductsName] = useState([]);
    const [attributeKeys, setAttributeKeys] = useState([]);
    const [attributeValues, setAttributeValues] = useState([]);

    const [mainCategoriesLoading, setMainCategoriesLoading] = useState(false);
    const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);
    const [brandsLoading, setBrandsLoading] = useState(false);
    const [productsNameLoading, setProductsNameLoading] = useState(false);
    const [attributeKeysLoading, setAttributeKeysLoading] = useState(false);
    const [attributeValuesLoading, setAttributeValuesLoading] = useState(false);

    const dropdownRefs = useRef([]);

    useImperativeHandle(ref, () => ({
        approveProductSelection,
        productValidataion
    }));

    useEffect(() => {
        const fetchAllProducts = async () => {
            setLoading(true);
            try {
                if (initiallySelectedProduct.value) {
                    const { data: { product } } = await getProductById(initiallySelectedProduct.value);
                    if (product) {
                        setSelectedProduct({
                            value: initiallySelectedProduct.value,
                            label: initiallySelectedProduct.label
                        });
                        updateProductDetails(product);
                        updateAttributesList(product.attributes);
                        resetErrors();
                    }
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAllProducts();
    }, []);


    const updateProductDetails = (product) => {
        const updateField = (setter, value) => setter({ value, label: value });

        updateField(setSelectedProductMainCategory, product.main_category);
        updateField(setSelectedProductSubCategory, product.sub_category);
        updateField(setSelectedProductBrand, product.brand);
    };

    const updateAttributesList = (attributes) => {
        const attributesList = Object.entries(attributes).map(([name, value]) => ({
            name: { value: name, label: name },
            value: { value, label: value }
        }));
        setSelectedAttributesList(attributesList);
    };

    const handleKeyDown = (e, currentIndex) => {
        if (e.key === 'Enter') {
            const nextIndex = currentIndex + 1;
            if (nextIndex < dropdownRefs.current.length) {
                const nextDropdown = dropdownRefs.current[nextIndex];
                if (nextDropdown) {
                    nextDropdown.focus();
                }
            }
        }
    };

    const fetchMainCategories = async () => {
        try {
            setMainCategoriesLoading(true);
            const response = await getMainCategories();
            const categories = response.data.main_categories.map(category => ({
                value: category,
                label: category,
            }));
            setMainCategories(categories);
        } catch (error) {
            console.error('Error fetching main categories:', error);
        } finally {
            setMainCategoriesLoading(false);
        }
    };

    const fetchSubCategories = async () => {
        try {
            setSubCategoriesLoading(true);
            let mainCategory = selectedProductMainCategory ? selectedProductMainCategory.value : selectedProductMainCategory;

            const response = await getSubCategories(mainCategory);
            const categories = response.data.sub_categories.map(category => ({
                value: category,
                label: category,
            }));
            setSubCategories(categories);
        } catch (error) {
            console.error('Error fetching sub categories:', error);
        } finally {
            setSubCategoriesLoading(false);
        }
    };

    const fetchBrands = async () => {
        try {
            setBrandsLoading(true);
            let mainCategory = selectedProductMainCategory ? selectedProductMainCategory.value : selectedProductMainCategory;
            let subCategory = selectedProductSubCategory ? selectedProductSubCategory.value : selectedProductSubCategory;

            const response = await getBrands(mainCategory, subCategory);
            const brands = response.data.brands.map(brand => ({
                value: brand,
                label: brand,
            }));
            setBrands(brands);
        } catch (error) {
            console.error('Error fetching brands:', error);
        } finally {
            setBrandsLoading(false);
        }
    };

    const fetchProducts = async () => {
        try {
            setProductsNameLoading(true);
            let mainCategory = selectedProductMainCategory ? selectedProductMainCategory.value : selectedProductMainCategory;
            let subCategory = selectedProductSubCategory ? selectedProductSubCategory.value : selectedProductSubCategory;
            let brand = selectedProductBrand ? selectedProductBrand.value : selectedProductBrand;

            const response = await searchProducts(mainCategory, subCategory, brand);
            const products_name_id = response.data.products.map(product => ({
                value: product.id,
                label: product.name,
            }));
            setProductsName(products_name_id);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setProductsNameLoading(false);
        }
    };

    const fetchAttributeKeys = async () => {
        try {
            setAttributeKeysLoading(true);
            let mainCategory = selectedProductMainCategory ? selectedProductMainCategory.value : selectedProductMainCategory;

            const response = await getProductAttributeKeys(mainCategory);
            const attribute_keys = response.data.attribute_keys.map(key => ({
                value: key,
                label: key,
            }));
            setAttributeKeys(attribute_keys);
        } catch (error) {
            console.error('Error fetching attribute keys:', error);
        } finally {
            setAttributeKeysLoading(false);
        }
    };

    const fetchAttributeValues = async () => {
        try {
            setAttributeValuesLoading(true);
            let mainCategory = selectedProductMainCategory ? selectedProductMainCategory.value : selectedProductMainCategory;

            const response = await getProductAttributeValues(mainCategory);
            const attribute_values = response.data.attribute_values.map(val => ({
                value: val,
                label: val,
            }));
            setAttributeValues(attribute_values);
        } catch (error) {
            console.error('Error fetching attribute values:', error);
        } finally {
            setAttributeValuesLoading(false);
        }
    };

    const handleProductMainCategoryCreate = (inputValue) => {
        const newValue = { value: inputValue, label: inputValue };
        setSelectedProductMainCategory(newValue);
        setShowMainCategoryError(false);
    };

    const handleProductMainCategoryChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== 'create') {
            setSelectedProductMainCategory(selectedOption);
            setShowMainCategoryError(false);
        } else {
            setSelectedProductMainCategory(null);
        }
    };

    const handleProductSubCategoryCreate = (inputValue) => {
        const newValue = { value: inputValue, label: inputValue };
        setSelectedProductSubCategory(newValue);
        setShowSubCategoryError(false);
    };

    const handleProductSubCategoryChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== 'create') {
            setSelectedProductSubCategory(selectedOption);
            setShowSubCategoryError(false);
        } else {
            setSelectedProductSubCategory(null);
        }
    };

    const handleProductBrandCreate = (inputValue) => {
        const newValue = { value: inputValue, label: inputValue };
        setSelectedProductBrand(newValue);
        setShowBrandError(false);
    };

    const handleProductBrandChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== 'create') {
            setSelectedProductBrand(selectedOption);
            setShowBrandError(false);
        } else {
            setSelectedProductBrand(null);
        }
    };

    const handleProductCreate = (inputValue) => {
        const newValue = { value: inputValue, label: inputValue };
        setSelectedProduct(newValue);
        setShowProductError(false);
    };

    const handleProductChange = async (selectedOption) => {
        try {
            setLoading(true);
            if (selectedOption && selectedOption.value !== 'create') {
                const productId = parseInt(selectedOption.value);
                const { data: { product } } = await getProductById(productId);
                if (product) {
                    setSelectedProduct(selectedOption);
                    updateProductDetails(product);
                    updateAttributesList(product.attributes);
                    resetErrors();
                }
                setShowProductError(false);
            } else {
                setSelectedProduct(null);
                setSelectedAttributesList([]);
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAttributeKeyChange = (index, name) => {
        const updatedSelectedAttributesList = [...selectedAttributesList];
        updatedSelectedAttributesList[index] = { name: name, value: '' };
        setSelectedAttributesList(updatedSelectedAttributesList);

        // Clear key error for the changed attribute
        const updatedAttributeErrors = [...attributeErrors];
        if (name && name.value !== "") {
            updatedAttributeErrors[index] = { ...updatedAttributeErrors[index], keyError: null };
        } else {
            updatedAttributeErrors[index] = { ...updatedAttributeErrors[index], keyError: "Key cannot be empty" };
        }
        setAttributeErrors(updatedAttributeErrors);
    };

    const handleAttributeValueChange = (index, options) => {
        const updatedSelectedAttributesList = [...selectedAttributesList];
        updatedSelectedAttributesList[index] = { name: selectedAttributesList[index].name, value: options };
        setSelectedAttributesList(updatedSelectedAttributesList);

        // Clear value error for the changed attribute
        const updatedAttributeErrors = [...attributeErrors];
        if (options && options.value !== "") {
            updatedAttributeErrors[index] = { ...updatedAttributeErrors[index], valueError: null };
        } else {
            updatedAttributeErrors[index] = { ...updatedAttributeErrors[index], valueError: "Value cannot be empty" };
        }
        setAttributeErrors(updatedAttributeErrors);
    };

    const handleRemoveCreatableSelect = (index) => {
        const updatedSelectedAttributesList = selectedAttributesList.filter((_, i) => i !== index);
        setSelectedAttributesList(updatedSelectedAttributesList);
    };

    const handleAddNewCreatableSelect = () => {
        setSelectedAttributesList([...selectedAttributesList, { name: '', value: '' }]);
    };

    function renderCreatableSelect(name, value, onChange, onCreateOption, onFocus, isLoading, options, placeholder, index, showError = false, className = styles.ChooseProductModalDropdown) {
        const combinedClassName = `${className} ${showError ? `${styles.errorField} ${styles.shake}` : ''}`;
        return (
            <CreatableSelect
                isClearable
                value={value}
                onFocus={onFocus}
                isLoading={isLoading}
                loadingMessage={() => "در حال جستجو ..."}
                options={options}
                onChange={onChange}
                className={combinedClassName}
                placeholder={placeholder}
                formatCreateLabel={(inputValue) => ` افزودن "${inputValue}"`}
                onCreateOption={onCreateOption}
                noOptionsMessage={() => "گزینه‌ای وجود ندارد"}
                ref={el => dropdownRefs.current[index] = el}
                onKeyDown={e => handleKeyDown(e, index)}
                styles={creatableSelectCustomStyles}
            />
        );
    }

    function renderCategorySelects() {
        return (
            <>
                <div className={styles.Field}>
                    <span className={styles.Subheading}>
                        گروه کالا:
                        <span style={{ color: "red" }}> *</span>
                    </span>
                    {renderCreatableSelect('mainCategory', selectedProductMainCategory, handleProductMainCategoryChange, handleProductMainCategoryCreate, fetchMainCategories, mainCategoriesLoading, mainCategories, "انتخاب گروه کالا", 0, showMainCategoryError)}
                </div>

                <div className={styles.Field}>
                    <span className={styles.Subheading}>
                        زیرگروه کالا:
                        <span style={{ color: "red" }}> *</span>
                    </span>
                    {renderCreatableSelect('subCategory', selectedProductSubCategory, handleProductSubCategoryChange, handleProductSubCategoryCreate, fetchSubCategories, subCategoriesLoading, subCategories, "انتخاب زیرگروه کالا", 1, showSubCategoryError)}
                </div>

                <div className={styles.Field}>
                    <span className={styles.Subheading}>
                        برند کالا:
                        <span style={{ color: "red" }}> *</span>
                    </span>
                    {renderCreatableSelect('brand', selectedProductBrand, handleProductBrandChange, handleProductBrandCreate, fetchBrands, brandsLoading, brands, "انتخاب برند کالا", 2, showBrandError)}
                </div>
            </>
        );
    }

    function renderProductSelect() {
        return (
            <>
                <div className={styles.Field}>
                    <span className={styles.Subheading}>
                        نام کالا
                        <span style={{ color: "red" }}> *</span>
                    </span>
                    {renderCreatableSelect('product', selectedProduct, handleProductChange, handleProductCreate, fetchProducts, productsNameLoading, productsName, "انتخاب کالا", 3, showProductError)}
                </div>
            </>
        );
    }

    function renderAttributesList() {
        return (
            <div className={styles.AddNewAttributeContainer}>
                <span className={styles.AttributesHeading}>ویژگی‌های کالا</span>
                {selectedAttributesList.map((selectedOptions, index) => {
                    const keyError = attributeErrors[index]?.keyError;
                    const valueError = attributeErrors[index]?.valueError;

                    return (
                        <div key={index} className={styles.NewAttributeContainer}>
                            {renderCreatableSelect(
                                'attributeKey',
                                selectedOptions.name,
                                (options) => handleAttributeKeyChange(index, options),
                                null,
                                fetchAttributeKeys,
                                attributeKeysLoading,
                                attributeKeys,
                                "عنوان ویژگی",
                                4,
                                keyError,
                            )}
                            {renderCreatableSelect(
                                'attributeValue',
                                selectedOptions.value,
                                (options) => handleAttributeValueChange(index, options),
                                null,
                                fetchAttributeValues,
                                attributeValuesLoading,
                                attributeValues,
                                "مقدار ویژگی",
                                5,
                                valueError,
                            )}
                            <button className={styles.RemoveButton}
                                onClick={() => handleRemoveCreatableSelect(index)}>حذف
                            </button>
                        </div>
                    );
                })}
                <button className={styles.AddNewAttributeButton} onClick={handleAddNewCreatableSelect}>
                    اضافه کردن ویژگی جدید
                </button>
            </div>
        );
    }

    const resetErrors = () => {
        setShowMainCategoryError(false);
        setShowSubCategoryError(false);
        setShowBrandError(false);
        setShowProductError(false);
    };

    const validateAttributes = () => {
        const errors = selectedAttributesList.map((attribute) => {
            const keyError = attribute.name === '' ? "Key cannot be empty" : null;
            const valueError = attribute.value === '' ? "Value cannot be empty" : null;

            return { keyError, valueError };
        });

        setAttributeErrors(errors);

        return errors.every(error => !error.keyError && !error.valueError);
    };

    const validateSelections = () => {
        let isValid = true;
        if (selectedProduct === null) {
            setShowProductError(true);
            isValid = false;
        }
        if (selectedProductMainCategory === null) {
            setShowMainCategoryError(true);
            isValid = false;
        }
        if (selectedProductSubCategory === null) {
            setShowSubCategoryError(true);
            isValid = false;
        }
        if (selectedProductBrand === null) {
            setShowBrandError(true);
            isValid = false;
        }
        if (!validateAttributes()) {
            isValid = false;
        }
        return isValid;
    };

    const getProduct = async (productId) => {
        try {
            const { data: { product } } = await getProductById(productId);
            return product;
        } catch (error) {
            // console.error('Error fetching product:', error);
            return null;
        }
    }

    const getNewAttributes = () => {
        const attributesJson = {};
        selectedAttributesList.forEach(attribute => {
            attributesJson[attribute.name.value] = attribute.value.value;
        });
        return attributesJson;
    };

    const isEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    const getProductChanges = (product) => {
        const changes = {};

        changes.newMainCategory = null;
        changes.newSubCategory = null;
        changes.newBrand = null;
        changes.newAttributes = null;

        if (selectedProductMainCategory.value !== product.main_category) {
            changes.newMainCategory = selectedProductMainCategory.value;
        }
        if (selectedProductSubCategory.value !== product.sub_category) {
            changes.newSubCategory = selectedProductSubCategory.value;
        }
        if (selectedProductBrand.value !== product.brand) {
            changes.newBrand = selectedProductBrand.value;
        }

        const newAttributes = getNewAttributes();
        if (!isEqual(newAttributes, product.attributes)) {
            changes.newAttributes = newAttributes;
        }

        return changes;
    };

    const handleExistingProduct = async (product) => {
        try {
            const changes = getProductChanges(product);
            const hasChanges = Object.values(changes).some(change => change !== null);

            if (hasChanges) {
                await updateProduct(product.id, ...Object.values(changes));
                // toast.success('بخش(های) مورد نظر با موفقیت ویرایش شد(ند).', {autoClose: 3000});
            }

            // await setInquiryProduct(inquiryId, product.id);
            // await addReceiptItem(receiptId, product.id, unit, unit_price, quantity);

            // handleSubmit();
            // setTimeout(handleSubmit, 3000);
        } catch (error) {
            console.error('Error handling existing product:', error);
            // toast.error('خطا در ویرایش کالا', {autoClose: 3000});
        }
    };

    const handleNewProduct = async () => {
        try {
            const newProductData = {
                main_category: selectedProductMainCategory.value,
                sub_category: selectedProductSubCategory.value,
                name: selectedProduct.value,
                brand: selectedProductBrand.value,
                attributes: getNewAttributes()
            };

            await addProduct(newProductData);

            const newProduct = await getProductByFields(newProductData);
            return newProduct.data.product.id;

            // toast.success('کالای جدید با موفقیت اضافه شد.', {autoClose: 3000});

            // await setInquiryProduct(inquiryId, newProduct.data.product.id);
            // await addReceiptItem(receiptId, newProduct.data.product.id, unit, unit_price, quantity);
            // setTimeout(handleSubmit, 3000);
        } catch (error) {
            console.error('Error handling new product:', error);
            // toast.error('خطا در افزودن کالای جدید', {autoClose: 3000});
        }
    };

    const productValidataion = () => {
        resetErrors();
        if (!validateSelections()) {
            return false;
        }
        return true;
    }

    const approveProductSelection = async (quantity, unit, unitPrice) => {
        let productId = selectedProduct.value;
        const product = await getProduct(productId);

        if (product) {
            await handleExistingProduct(product);
        } else {
            productId = await handleNewProduct();
        }

        const newProduct = {
            id: productId,
            main_category: selectedProductMainCategory.value,
            sub_category: selectedProductSubCategory.value,
            name: selectedProduct.label,
            brand: selectedProductBrand.value,
            attributes: getNewAttributes(),
            quantity: quantity,
            unit: unit,
            unitPrice: unitPrice
        };
        addProductToReceipt(newProduct);
    };

    return (
        <div className={styles.ChooseContainer}>
            {renderCategorySelects()}
            {renderProductSelect()}
            {selectedProduct && renderAttributesList()}
            {loading && <LoadingComponent isMobile={false} />}
        </div>
    );
});

export default SelectProduct;