import React, {useState} from 'react';
import {Modal, Box, Typography, Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";

const RankingTable = ({headers, data, tableTitle}) => {
    const [showAll, setShowAll] = useState(false);

    const top3ProductRankingData = data.slice(0, 3);

    const renderTable = (tableData) => (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <TableCell
                            key={index}
                            style={{
                                fontFamily: "'IRANSansFaNum', sans-serif",
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                fontSize: '0.9vw',
                                padding: '0.5vw',
                                textAlign: 'center',
                                // Conditionally apply styles based on showAll state
                                whiteSpace: !showAll ? 'nowrap' : 'normal',
                                overflow: !showAll ? 'hidden' : 'visible',
                                textOverflow: !showAll ? 'ellipsis' : 'clip',
                                maxWidth: !showAll ? '10vw' : 'none',   // Adjust maxWidth as per your design
                            }}
                        >
                            {header.value}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <TableCell
                                key={cellIndex}
                                style={{
                                    fontFamily: "'IRANSansFaNum', sans-serif",
                                    borderBottom: 'none',
                                    fontSize: '0.9vw',
                                    padding: '0.75vw',
                                    textAlign: 'center',
                                    whiteSpace: !showAll ? 'nowrap' : 'normal',
                                    overflow: !showAll ? 'hidden' : 'visible',
                                    textOverflow: !showAll ? 'ellipsis' : 'clip',
                                    maxWidth: !showAll ? '10vw' : 'none',   // Adjust maxWidth as per your design
                                }}
                            >
                                {cell.value}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    return (
        <>
            <div style={{height: '100%', width: '100%'}}>
                <>
                    {renderTable(top3ProductRankingData)}
                    <button onClick={() => setShowAll(true)}
                            style={{
                                background: 'inherit', border: 'none', cursor: 'pointer',
                                fontFamily: 'IRANSans', color: 'var(--primary-light-color)',
                                fontWeight: 'bold', marginTop: '0.5vw', fontSize: '1vw',
                                display: 'flex', alignItems: 'center', gap: '0.5vw', marginRight: 'auto'
                            }}>
                        <span>مشاهده همه</span>
                        <span style={{transform: 'rotate(45deg)', fontSize: '1.5vw'}}>&#x2190;</span>
                    </button>
                </>
            </div>

            {showAll && (
                <Modal
                    open={showAll}
                    onClose={() => setShowAll(false)}
                    aria-labelledby="modal-title"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 700,
                            bgcolor: "background.paper",
                            border: "2px solid white",
                            boxShadow: 24,
                            p: 5,
                            borderRadius: '10px',
                            direction: 'rtl',
                            maxHeight: '60vh',
                            overflowY: 'auto'
                        }}
                    >
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                textAlign: "center",
                                fontFamily: "'IRANSans', sans-serif",
                                fontWeight: "bold",
                                marginBottom: '2vw',
                                color: 'var(--primary-dark-color)'
                            }}
                        >
                            {tableTitle}
                        </Typography>
                        {renderTable(data)}
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default RankingTable;
