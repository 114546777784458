import axios from "./base";

export function getMainCategories() {
    return axios.get(`/product/main-categories/`);
}

export function getSubCategories(mainCategory = null) {
    const params = new URLSearchParams();
    if (mainCategory) {
        params.append('main_category', mainCategory);
    }

    return axios.get(`/product/sub-categories/?${params.toString()}`);
}

export function getBrands(mainCategory = null, subCategory = null) {
    const params = new URLSearchParams();
    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (subCategory) {
        params.append('sub_category', subCategory);
    }

    return axios.get(`/product/brands/?${params.toString()}`);
}

export function searchProducts(mainCategory = null, subCategory = null, brand = null, name = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (subCategory) {
        params.append('sub_category', subCategory);
    }
    if (brand) {
        params.append('brand', brand);
    }
    if (name) {
        params.append('name', name);
    }
    return axios.get(`/product/get/?${params.toString()}`);
}

export function searchProductsByName(product_name) {
    return axios.get(`/product/name/${product_name}`);
}

export function getProductAttributeKeys(mainCategory = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    return axios.get(`/product/attributes-keys/?${params.toString()}`);
}

export function getProductAttributeValues(mainCategory = null, key = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (key) {
        params.append('key', key);
    }
    return axios.get(`/product/attributes-values/?${params.toString()}`);
}

export function addProduct(newProductData) {
    return axios.post(`product/add/`, newProductData);
}

export function updateProduct(product_id, new_main_category = null, new_sub_category = null,
                              new_brand = null, new_attributes = null) {
    const data = {product_id: product_id};

    if (new_main_category !== null) {
        data.new_main_category = new_main_category;
    }
    if (new_sub_category !== null) {
        data.new_sub_category = new_sub_category;
    }
    if (new_brand !== null) {
        data.new_brand = new_brand;
    }
    if (new_attributes !== null) {
        data.new_attributes = new_attributes;
    }

    return axios.post('product/modify/', data);
}

export function getProductByFields(newProductData) {
    return axios.post(`product/get-by-fields/`, newProductData);
}

export function setInquiryProduct(inquiry_id, product_id) {
    return axios.post(`inquiry/set-product/`, {inquiry_id: inquiry_id, product_id: product_id})
}

export function addReceiptItem(receipt_id, product_id, unit, unit_price, quantity) {
    return axios.post(`receipt/add-item/`, {
        receipt_id: receipt_id,
        product_id: product_id,
        unit: unit,
        unit_price: unit_price,
        quantity: quantity
    });
}

export function getProductById(productId) {
    return axios.get(`/product/${productId}`);
}