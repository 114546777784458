import React, {useRef, useState} from "react";
import useNumberOfRequestsLineChart
    from "../../hooks/dashboard/overview_section/useNumberOfRequestsLineChart";
import useNumberOfRequests from "../../hooks/dashboard/overview_section/useNumberOfRequests";
import useTotalProfit from "../../hooks/dashboard/overview_section/useTotalProfit";
import useProductMainCategoryRankingTable
    from "../../hooks/dashboard/overview_section/useProductMainCategoryRankingTable";
import styles from "../../styles/auditor/desktop/dashboard/OverviewSection.module.css";
import common_styles from "../../styles/auditor/desktop/dashboard/common.module.css";
import ReactLoading from "react-loading";
import CountUp from 'react-countup';
import useNumberOfOrganizations from "../../hooks/dashboard/overview_section/useNumberOfOrganizations";
import useNumberOfUsers from "../../hooks/dashboard/overview_section/useNumberOfUsers";
import {IoGridOutline} from "@react-icons/all-files/io5/IoGridOutline";
import {MdShowChart} from "@react-icons/all-files/md/MdShowChart";
import {FaTable} from "@react-icons/all-files/fa/FaTable";
import useRequestProcessingMeanTime
    from "../../hooks/dashboard/overview_section/useRequestProcessingMeanTime";
import RankingTable from "../../components/RankingTable/RankingTable";
import useStoresRankingTable from "../../hooks/dashboard/overview_section/useStoresRankingTable";
import ProfitComponent from "./common/ProfitComponent";

const OverviewSection = ({filters, role}) => {
    // number of requests
    const [numberOfRequestsLoading, setNumberOfRequestsLoading] = useState(false);
    const numberOfRequests = useNumberOfRequests(filters, setNumberOfRequestsLoading);

    // profit
    const [totalProfitLoading, setTotalProfitLoading] = useState(false);
    const [profitDisplayMode, setProfitDisplayMode] = useState('rial');
    const totalProfit = useTotalProfit(filters, setTotalProfitLoading);
    const handleProfitDisplayModeChange = (e) => {
        setProfitDisplayMode(e.target.value);
    };

    // inquiry processing time
    const [requestProcessingMeanTimeLoading, setRequestProcessingMeanTimeLoading] = useState(false);
    const requestProcessingMeanTime = useRequestProcessingMeanTime(filters, setRequestProcessingMeanTimeLoading);

    // number of requests over time chart
    const [numberOfRequestsLineChartLoading, setNumberOfRequestsLineChartLoading] = useState(false);
    const numberOfRequestsLineChartRef = useRef(null);
    const numberOfRequestsLineChartInstanceRef = useRef(null);
    useNumberOfRequestsLineChart(numberOfRequestsLineChartRef, numberOfRequestsLineChartInstanceRef, filters, setNumberOfRequestsLineChartLoading);

    // product main categories ranking table
    const [topMainCategoriesLoading, setTopMainCategoriesLoading] = useState(false);
    const {
        productMainCategoryRankingHeaders,
        productMainCategoryRankingData
    } = useProductMainCategoryRankingTable(filters, setTopMainCategoriesLoading);

    // stores ranking table
    const [topStoresLoading, setTopStoresLoading] = useState(false);
    const {
        storesRankingHeaders,
        storesRankingData
    } = useStoresRankingTable(filters, setTopStoresLoading);

    // number of organizations
    const [numberOfOrganizationsLoading, setNumberOfOrganizationsLoading] = useState(false);
    const numberOfOrganizations = useNumberOfOrganizations(filters, setNumberOfOrganizationsLoading);

    // number of users
    const [numberOfUsersLoading, setNumberOfUsersLoading] = useState(false);
    const numberOfUsers = useNumberOfUsers(filters, setNumberOfUsersLoading);

    return (
        <div className={styles.overview_section_container}>
            <div className={styles.overview_section_first_row}>
                <div className={common_styles.statistic_container}>
                    <div className={common_styles.section_header_container}>
                        <IoGridOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            تعداد کل درخواست‌ها
                        </span>
                    </div>
                    {numberOfRequestsLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                          height='50px'/>
                        </div>
                        :
                        <span className={common_styles.statistic_text}>
                            <CountUp end={numberOfRequests} duration={5}/>
                        </span>
                    }
                </div>

                <ProfitComponent
                    loading={totalProfitLoading}
                    value={totalProfit}
                    displayMode={profitDisplayMode}
                    onDisplayModeChange={handleProfitDisplayModeChange}
                />

                <div className={common_styles.statistic_container}>
                    <div className={common_styles.section_header_container}>
                        <IoGridOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            میانگین زمان بررسی درخواست‌ها
                        </span>
                    </div>
                    {requestProcessingMeanTimeLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                          height='50px'/>
                        </div>
                        :
                        <span className={common_styles.statistic_text}>
                            <CountUp end={requestProcessingMeanTime} duration={5}/>
                            {'  روز'}
                        </span>
                    }
                </div>
            </div>
            <div className={styles.overview_section_second_row}>
                <div className={common_styles.trend_chart}>
                    <div className={common_styles.section_header_container}>
                        <MdShowChart className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            نمودار روند تعداد درخواست‌ها در طول زمان
                        </span>
                    </div>
                    {numberOfRequestsLineChartLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.trend_chart_body}>
                            <canvas ref={numberOfRequestsLineChartRef}></canvas>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.overview_section_third_row}>
                <div className={common_styles.ranking_table_container}>
                    <div className={common_styles.section_header_container}>
                        <FaTable className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            گروه‌های کالایی با بیشترین مجموع خرید
                        </span>
                    </div>
                    {topMainCategoriesLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.ranking_table}>
                            <RankingTable
                                headers={productMainCategoryRankingHeaders}
                                data={productMainCategoryRankingData}
                                tableTitle={"گروه‌های کالایی با بیشترین مجموع خرید"}
                            />
                        </div>
                    }
                </div>
                <div className={common_styles.ranking_table_container}>
                    <div className={common_styles.section_header_container}>
                        <FaTable className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            فروشندگان برتر
                        </span>
                    </div>
                    {topStoresLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.ranking_table}>
                            <RankingTable
                                headers={storesRankingHeaders}
                                data={storesRankingData}
                                tableTitle={"فروشندگان با بیشترین مجموع خرید"}
                            />
                        </div>
                    }
                </div>

                {role === "auditor" &&
                    <div className={styles.cards_column_container}>
                        <div className={common_styles.mini_statistic_container}>
                            <div className={common_styles.section_header_container}>
                                <IoGridOutline className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    تعداد سازمان‌ها
                                </span>
                            </div>
                            {numberOfOrganizationsLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                                  height='50px'/>
                                </div>
                                :
                                <span className={common_styles.statistic_text}>
                                    <CountUp end={numberOfOrganizations} duration={5}/>
                                </span>
                            }
                        </div>
                        <div className={common_styles.mini_statistic_container}>
                            <div className={common_styles.section_header_container}>
                                <IoGridOutline className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    تعداد کاربران
                                </span>
                            </div>
                            {numberOfUsersLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                                  height='50px'/>
                                </div>
                                :
                                <span className={common_styles.statistic_text}>
                                    <CountUp end={numberOfUsers} duration={5}/>
                                </span>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default OverviewSection;
