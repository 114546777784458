import axios from "./base";

export function getInquiriesWithStatus(inquiry_status, page, pageSize, inquiry_name = null, inquiry_creator = null,
                                       org_name = null, product_name = null, sort_key = null, sort_direction = null) {
    const params = new URLSearchParams();

    if (inquiry_name) {
        params.append('inquiry_name', inquiry_name);
    }
    if (inquiry_creator) {
        params.append('inquiry_creator', inquiry_creator);
    }
    if (org_name) {
        params.append('org_name', org_name);
    }
    if (product_name) {
        params.append('product_name', product_name);
    }
    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`inquiry/inquiries/${inquiry_status}/?${params.toString()}`);
}

export function getInquiryCounts() {
    return axios.get(`inquiry/counts/`);
}

export function getUserInquiries(inquiry_status, page, pageSize, sort_key = null, sort_direction = null) {
    const params = new URLSearchParams();
    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }

    params.append('page', page);
    params.append('page_size', pageSize);

    if (inquiry_status === 0) {
        return axios.get(`inquiry/user_inquiries/?${params.toString()}`);
    } else {
        return axios.get(`inquiry/user_inquiries/${inquiry_status}/?${params.toString()}`);
    }
}

export function createInquiry(photos, description, subject, user_selected_photo_index, onUploadProgress) {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('subject', subject);
    formData.append('user_selected_photo_index', user_selected_photo_index);
    photos.forEach((photo) => {
        formData.append('photos', photo.data_uri);
    });

    return axios.post(`inquiry/create/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
            if (onUploadProgress) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgress(progress); // Call the passed in progress callback
            }
        },
    });
}

// export function createFile(inquiry_id, file, is_selected_by_user, onUploadProgress) {
//     const formData = new FormData();
//     formData.append('inquiry_id', inquiry_id);
//     formData.append('file', file);
//     formData.append('is_selected_by_user', is_selected_by_user);
//
//     return axios.post(`inquiry/create-file/`, formData, {
//         headers: {
//             'Content-Type': 'multipart/form-data',
//         },
//         onUploadProgress: (progressEvent) => {
//             if (onUploadProgress) {
//                 const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//                 onUploadProgress(progress); // Call the passed in progress callback
//             }
//         },
//     });
// }

export function editInquiry(inquiry_id, photos, user_selected_photo_index) {
    const formData = new FormData();
    formData.append('inquiry_id', inquiry_id);
    formData.append('user_selected_photo_index', user_selected_photo_index);

    for (let i = 0; i < photos.length; i++) {
        if (photos[i].id === -1) {
            formData.append('photos', photos[i].data_uri);
        } else {
            formData.append('photo_ids', photos[i].id);
        }
    }

    return axios.put(`inquiry/edit/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function getInquiryWithPhotos(inquiry_id) {
    return axios.get(`inquiry/${inquiry_id}/with-photos/`);
}

export function getInquiry(inquiry_id) {
    return axios.get(`inquiry/${inquiry_id}/`);
}

export function getInquiryReceipts(inquiry_id) {
    return axios.get(`inquiry/${inquiry_id}/receipts/`);
}

export function finalizeInquiry(inquiry_id) {
    return axios.post(`inquiry/finalize/`, {inquiry_id: inquiry_id});
}

export function cancelInquiry(inquiry_id) {
    return axios.post(`inquiry/cancel/`, {inquiry_id: inquiry_id});
}

export function addComment(inquiry_id, comment) {
    return axios.post(`inquiry/new-comment/`, {inquiry_id: inquiry_id, comment: comment});
}

export function returnInquiry(inquiry_id) {
    return axios.post(`inquiry/return/${inquiry_id}/`);
}

export function getInquiryEvents() {
    return axios.get(`notification/all/`);
}

export function getInquiryStatus(inquiry_id) {
    return axios.get(`inquiry/status/${inquiry_id}/`);
}

export function readAllInquiryEvents() {
    return axios.post(`notification/read-all/`);
}

export function readInquiryEvent(inquiry_event_id) {
    return axios.post(`notification/read/${inquiry_event_id}/`);
}

export function getInquiryEventsCount() {
    return axios.get(`notification/count/`);
}

export function getInquiryHistory(inquiry_id) {
    return axios.get(`notification/${inquiry_id}/history/`);
}

export function getUnopenedInquiryEventsCountBatch(inquiryIds) {
    return axios.post(`/notification/batch-count/`, {inquiry_ids: inquiryIds});
}

export function openInquiryEvents(inquiry_id) {
    return axios.post(`notification/${inquiry_id}/open/`);
}

export function getFirstCommentAfterReturned(inquiry_id) {
    return axios.get(`inquiry/${inquiry_id}/first-comment-after-returned/`);
}

export function getPhoto(photo_id, inquiry_id) {
    return axios.get(`inquiry/${inquiry_id}/photo/${photo_id}/`);
}