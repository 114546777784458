import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import toast_style from "../../styles/Toastify.css";
import auth_image from "../../assets/images/auth_image.svg";
import {useMobile} from "../../MobileContext";
import {fetchCurrentUser, login, signup} from "../../APIs/auth";
import InlineError from "../../components/InlineError/InlineError";

import mobile_styles from "../../styles/auth/SignupMobile.module.css";
import desktop_styles from "../../styles/auth/SignupDesktop.module.css";
import {user_role_map} from "./Consts";

const Signup = () => {
    const navigate = useNavigate();
    const isMobile = useMobile();
    const styles = isMobile ? mobile_styles : desktop_styles;
    const [loading, setLoading] = useState(false);

    // Input Fields
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [fullname, setFullname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [orgCode, setOrgCode] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    // Errors
    const [showRePasswordError, setShowRePasswordError] = useState(false);
    const [showEmailStructureError, setShowEmailStructureError] = useState(false);
    const [showPhoneStructureError, setShowPhoneStructureError] = useState(false);
    const [showNotFoundOrgError, setShowNotFoundOrgError] = useState(false);
    const [showUsernameStructureError, setShowUsernameStructureError] = useState(false);
    const [showUsernameAlreadyExistsError, setShowUsernameAlreadyExistsError] = useState(false);
    const [showPhoneAlreadyExistsError, setShowPhoneAlreadyExistsError] = useState(false);
    const [showPasswordStructureError, setShowPasswordStructureError] = useState(false);
    const [showPasswordLengthError, setShowPasswordLengthError] = useState(false);

    // Consts
    const USERNAME_ALREADY_EXISTS = "Username already exists. Please try another one.";
    const PHONE_ALREADY_EXISTS = "Phone already exists. Please try another one.";
    const ORGANIZATION_NOT_FOUND = "Organization not found.";

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        switch (name) {
            case 'fullname':
                setFullname(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'orgCode':
                setOrgCode(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 're-password':
                setRePassword(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            setShowPhoneStructureError(false);
            setShowEmailStructureError(false);
            setShowRePasswordError(false);
            setShowNotFoundOrgError(false);
            setShowUsernameStructureError(false);
            setShowUsernameAlreadyExistsError(false);
            setShowPhoneAlreadyExistsError(false);
            setShowPasswordStructureError(false);
            setShowPasswordLengthError(false);

            // not handling persian digits because the keyboard may be arabic or ...
            if (!/^09\d{9}$/.test(phone)) {
                setShowPhoneStructureError(true);
            } else if (!emailRegex.test(email)) {
                setShowEmailStructureError(true);
            } else if (!/^[a-zA-Z0-9_]+$/.test(username)) {
                setShowUsernameStructureError(true);
            } else if (password.length < 8) {
                setShowPasswordLengthError(true);
            } else if (!/^[a-zA-Z0-9_@#%&]+$/.test(password)) {
                setShowPasswordStructureError(true);
            } else if (password !== rePassword) {
                setShowRePasswordError(true);
            } else {
                // signup
                await signup(fullname, phone, email, orgCode, username, password);

                // login
                const response = await login(username, password);
                localStorage.clear();
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                const user = await fetchCurrentUser();
                const user_role = user_role_map[user.data.user.groups[0]];

                toast.success('با موفقیت ثبت‌نام شدید.', {autoClose: 3000});

                setTimeout(() => {
                    if (user_role === "Client") {
                        navigate("/user");
                    } else if (user_role === "FinancialManager") {
                        navigate("/financial-manager");
                    } else if (["Auditor", "Manager", "CEO", "Admin"]
                        .includes(user_role)) {
                        navigate("/auditor");
                    }
                }, 3000);
            }
        } catch (error) {
            console.error('Signup failed:', error);

            if (error.response && error.response.data) {
                if (error.response.status === 400) {
                    if (error.response.data.error === USERNAME_ALREADY_EXISTS) {
                        setShowUsernameAlreadyExistsError(true);
                    } else if (error.response.data.error === PHONE_ALREADY_EXISTS) {
                        setShowPhoneAlreadyExistsError(true);
                    } else if (error.response.data.error === ORGANIZATION_NOT_FOUND) {
                        setShowNotFoundOrgError(true);
                    } else {
                        navigate("/500");
                    }
                } else {
                    navigate("/500");
                }
            } else {
                navigate("/500");
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <div className={styles.SignupContainer}>
                <ToastContainer className={toast_style}/>
                {!isMobile && <img src={auth_image} width={"100%"} height={"100%"} alt={"img"}/>}
                <div className={styles.SignupContent}>
                    <h3 className={styles.HeadingThree}>ایران‌تدارکات</h3>
                    <h2 className={styles.HeadingTwo}>ثبت‌نام</h2>
                    <form className={styles.SignupForm} onSubmit={handleSubmit}>
                        <div className={styles.PersonalInfoContainer}>
                            <h3 className={styles.InfoHeading}>مشخصات فردی</h3>
                            <input type="text" id="fullname" name="fullname" placeholder={"نام و نام خانوادگی"}
                                   className={styles.InputField} value={fullname}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            <input type="text" id="phone" name="phone" placeholder={"شماره تلفن همراه"}
                                   className={styles.InputField} value={phone}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showPhoneStructureError && (
                                <InlineError
                                    errorMessage={"شماره تلفن همراه وارد شده صحیح نمی‌باشد. باید ۱۱ رقم باشد، با ۰۹ شروع شود و فقط شامل اعداد انگلیسی باشد."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            {showPhoneAlreadyExistsError && (
                                <InlineError
                                    errorMessage={"با این شماره قبلا یک حساب ثبت شده است."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            <input type="text" id="email" name="email" placeholder={"ایمیل"}
                                   className={styles.InputField} value={email}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showEmailStructureError && (
                                <InlineError
                                    errorMessage={"آدرس ایمیل به درستی وارد نشده است."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                        </div>
                        <div className={styles.UserInfoContainer}>
                            <h3 className={styles.InfoHeading}>مشخصات کاربری</h3>
                            <input type="text" id="orgCode" name="orgCode" placeholder={"کد سازمان"}
                                   className={styles.InputField} value={orgCode}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showNotFoundOrgError && (
                                <InlineError
                                    errorMessage={"کد سازمان وارد شده در سامانه موجود نیست."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            <input type="text" id="username" name="username" placeholder={"نام کاربری"}
                                   className={styles.InputField} value={username}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showUsernameStructureError && (
                                <InlineError
                                    errorMessage={"نام کاربری باید فقط شامل حروف انگلیسی و علامت خط تیره (_) باشد."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            {showUsernameAlreadyExistsError && (
                                <InlineError
                                    errorMessage={"با این نام کاربری قبلا یک حساب ثبت شده است."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            <input type="password" id="password" name="password" placeholder={"رمز عبور"}
                                   className={styles.InputField} value={password}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showPasswordStructureError && (
                                <InlineError
                                    errorMessage={"در رمز عبور، تنها می‌توانید از کاراکترهای ویژه _@#%& استفاده کنید."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            {showPasswordLengthError && (
                                <InlineError
                                    errorMessage={"طول رمز عبور باید بیشتر از ۸ کاراکتر باشد."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                            <input type="password" id="re-password" name="re-password" placeholder={"تکرار رمز عبور"}
                                   className={styles.InputField} value={rePassword}
                                   onChange={handleInputChange}
                                   onInvalid={(e) => {
                                       e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                   }}
                                   onInput={(e) => {
                                       e.target.setCustomValidity('');
                                   }}
                                   required/>
                            {showRePasswordError && (
                                <InlineError
                                    errorMessage={"تکرار رمز عبور به درستی وارد نشده است."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            )}
                        </div>
                        <button type="submit" className={styles.ButtonField} disabled={loading}>
                            {!loading ? 'ثبت‌نام' : 'لطفا صبر کنید ...'}
                        </button>
                    </form>
                    <span className={styles.TextField}>حساب کاربری دارید؟</span>
                    <button type="submit" className={styles.LoginButtonField}
                            onClick={() => navigate(`/login`)}> ورود
                    </button>
                </div>
            </div>
        </>
    );
};

export default Signup;
