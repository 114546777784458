import styles from "../../styles/auditor/desktop/AuditorInquiries.module.css";
import React, {useState} from "react";
import CustomTable from "../../components/CustomTable/CustomTable";
import {useNavigate} from "react-router-dom";
import NoInquiryIcon from "../../assets/images/NoInquiryIcon.svg"
import LoadingComponent from "../../components/Loading/Loading";
import convertDateToShamsi from "../../utils/functions"
import StatusNavbar from "../../components/StatusNavbar/StatusNavbar";
import {openInquiryEvents} from "../../APIs/inquiry";
import InquiryUpdates from "../../components/InquiryUpdatesIcon/InquiryUpdates";
import useAuditorInquiries from "../../hooks/useAuditorInquiries";
import Pagination from "../../components/Pagination/Pagination";

const InquiriesDesktop = ({userRole}) => {
    const navigate = useNavigate();

    const [inquiriesPerPage] = useState(8);
    const {
        loading,
        inquiryStatus,
        setInquiryStatus,
        inquiries,
        totalInquiries,
        inquiryCounts,
        updateCounts,
        currentPage,
        setCurrentPage,
        sortConfig,
        setSortConfig,
        searchCategory,
        setSearchCategory,
        searchQuery,
        setSearchQuery,
        isSearchPerformed,
        setIsSearchPerformed,
        fetchInquiries
    } = useAuditorInquiries(userRole, inquiriesPerPage);
    const indexOfLastInquiry = currentPage * inquiriesPerPage;
    const indexOfFirstInquiry = indexOfLastInquiry - inquiriesPerPage;
    const totalPages = Math.ceil(totalInquiries / inquiriesPerPage);

    const inquiryStatuses = {
        Statuses: [
            {id: 0, status: "تمام درخواست‌ها"},
            {id: 1, status: "در انتظار پردازش"},
            {id: 2, status: "در حال بررسی"},
            {id: 3, status: "بررسی شده"},
            {id: 4, status: "خاتمه یافته"},
        ]
    };

    const handleSort = (columnIndex) => {
        const newDirection = sortConfig.key === columnIndex && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({key: columnIndex, direction: newDirection});
    };

    async function handleNavigateToInquiryPage(inquiry_id) {
        try {
            if (userRole === 'auditor') {
                await openInquiryEvents(inquiry_id);
            }
            navigate(`/${userRole}/inquiry/${inquiry_id}`);
        } catch (error) {
            console.error('Error in opening inquiry event: ', error);
        }
    }

    function renderInquiryActionButton(inquiry) {
        if (userRole === 'auditor') {
            if (inquiry.status === 1) {
                return "پردازش پیش‌فاکتور‌ها";
            } else if (inquiry.status === 2) {
                return "بررسی درخواست";
            } else if ([3, 4].includes(inquiry.status)) {
                return "مشاهده درخواست";
            }
        } else {
            return "مشاهده درخواست";
        }
    }

    const handleChangeInquiryStatus = (status) => {
        setInquiryStatus(status);
        setCurrentPage(1);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        let startPage, endPage;

        if (totalPages <= maxPagesToShow) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= halfMaxPagesToShow) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
        }

        if (startPage > 1) {
            pageNumbers.push(
                <span
                    key={1}
                    className={`${styles.PageNumber} ${currentPage === 1 ? styles.Active : ''}`}
                    onClick={() => setCurrentPage(1)}
                >
                    1
                </span>
            );
            if (startPage > 2) {
                pageNumbers.push(<span key="ellipsis-start">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`${styles.PageNumber} ${currentPage === i ? styles.Active : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </span>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(<span key="ellipsis-end">...</span>);
            }
            pageNumbers.push(
                <span
                    key={totalPages}
                    className={`${styles.PageNumber} ${currentPage === totalPages ? styles.Active : ''}`}
                    onClick={() => setCurrentPage(totalPages)}
                >
                    {totalPages}
                </span>
            );
        }

        return pageNumbers;
    };

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filterInquiries = async () => {
        setIsSearchPerformed(true);
        setCurrentPage(1);
        await fetchInquiries();
    };

    const generateTableHeaders = () => {
        const headers = [
            {value: "شناسه"},
            {value: "عنوان"},
            {value: "تاریخ", sort: true, onSort: () => handleSort(3)},
            {value: "ایجاد کننده", sort: true, onSort: () => handleSort(4)},
            {value: "نام شرکت", sort: true, onSort: () => handleSort(5)},
            {value: ""}
        ];

        if (userRole === 'auditor') {
            headers.unshift({value: "", width: "40px"});
        }

        return [headers];
    };

    function generateTableRows() {
        if (inquiries.length !== 0) {
            return inquiries.map((inquiry) => {
                const row = [
                    {value: inquiry.name},
                    {value: inquiry.subject},
                    {value: convertDateToShamsi(inquiry.submitted_date)},
                    {value: inquiry.fullname},
                    {value: inquiry.organization},
                    {
                        value: (
                            <button
                                className={styles.detailExpandButton}
                                onClick={() => handleNavigateToInquiryPage(inquiry.id)}
                            >
                                {renderInquiryActionButton(inquiry)}
                            </button>
                        )
                    }
                ];

                if (userRole === 'auditor') {
                    row.unshift({
                        value: (
                            <InquiryUpdates
                                count={updateCounts[inquiry.id] || 0}
                                isAuditor={true}
                            />
                        ),
                        width: "40px"
                    });
                }

                return row;
            });
        } else {
            return [];
        }
    }

    const tableRows = generateTableRows();
    const tableHeaders = generateTableHeaders();

    return (
        <div
            className={styles.InquiriesContainer}>
            {userRole === 'auditor' && (
                <StatusNavbar
                    inquiryStatus={inquiryStatus}
                    handleInquirySelect={handleChangeInquiryStatus}
                    isAuditor={true}
                    inquiryCounts={inquiryCounts}
                />
            )}
            {userRole !== 'auditor' && <h3 className={styles.Header}>لیست درخواست‌ها</h3>}
            <div className={styles.SearchContainer}>
                <span className={styles.SearchText}>جستجو براساس: </span>
                <select className={styles.SearchDropDown} value={searchCategory}
                        onChange={(event) => setSearchCategory(event.target.value)}>
                    <option value="all">همه موارد</option>
                    <option value="subject">عنوان درخواست</option>
                    <option value="fullname">نام ایجاد کننده</option>
                    <option value="org">نام شرکت</option>
                    <option value="product">نام کالا</option>
                </select>
                <input className={styles.SearchInput} placeholder={"عبارت مورد جستجو"} value={searchQuery}
                       onChange={handleSearchQueryChange}/>
                <button className={styles.SearchButton} onClick={() => filterInquiries()}>
                    جستجو
                </button>
            </div>

            {inquiries.length === 0 ?
                <div className={styles.EmptyInquiriesContainer}>
                    <img src={NoInquiryIcon} className={styles.EmptyInquiries} alt={"No Inquiry!"}/>
                    <span className={styles.EmptyInquiriesMessage}>
                        {inquiries.length === 0 && isSearchPerformed ?
                            (inquiryStatus === 0 ?
                                    "درخواستی مطابق با جستجوی شما در سیستم ثبت نشده است."
                                    :
                                    `درخواستی با وضعیت "${inquiryStatuses.Statuses[inquiryStatus].status}" و مطابق با جستجوی شما در سیستم ثبت نشده است.`
                            )
                            :
                            (inquiryStatus === 0 ?
                                    "درخواستی در سیستم ثبت نشده است."
                                    :
                                    `درخواستی با وضعیت "${inquiryStatuses.Statuses[inquiryStatus].status}" در سیستم ثبت نشده است.`
                            )
                        }
                    </span>
                </div>
                :
                <div className={styles.TableContainer}>
                    {loading ?
                        <LoadingComponent isMobile={false}/>
                        :
                        <div className={styles.InquiriesTableContainer}>
                            <CustomTable
                                headerData={tableHeaders}
                                data={tableRows}
                                styles={"desktop"}
                                expandedRows={[]}
                                sortConfig={sortConfig}
                                onSort={handleSort}
                            />
                        </div>
                    }
                    {!loading && inquiries.length > 0 && (
                        <div className={styles.PaginationWrapper}>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={setCurrentPage}
                            />
                            <div className={styles.ItemCount}>
                                نمایش
                                موارد {indexOfFirstInquiry + 1} تا {Math.min(indexOfLastInquiry, totalInquiries)} از {totalInquiries} مورد
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default InquiriesDesktop;