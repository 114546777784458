import { useMobile } from "../../MobileContext";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import InquiryDesktop from "../common/InquiryDesktop";
import React from "react";
import styles from "../../styles/PageLayout.module.css";

const ManagerInquiry = () => {
    const isMobile = useMobile();

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <> </>
            ) : (
                <DesktopHeader
                    role={"manager"}
                    showNotification={false}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={styles.rightSectionContainer}>
                    {isMobile ? (
                        <> </>
                    ) : (
                        <InquiryDesktop isNotificationPanelOpen={false} isFinancialManager={false} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ManagerInquiry;