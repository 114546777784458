import { useState, useEffect } from 'react';
import {getNumberOfUsers, getRequestProcessingMeanTime} from "../../../APIs/auditor_dashboard";

const useRequestProcessingMeanTime = (filters, setLoading) => {
    const [data, setData] = useState(0);

    const fetchRequestProcessingMeanTime = async () => {
        try {
            setLoading(true);
            const response = await getRequestProcessingMeanTime(filters);
            setData(response.data.count);
        } catch (error) {
            console.error('Error fetching number of users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRequestProcessingMeanTime();
    }, [filters]);

    return data;
};

export default useRequestProcessingMeanTime;
