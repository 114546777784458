import React from "react";
import {IoGridOutline} from "@react-icons/all-files/io5/IoGridOutline";
import ReactLoading from "react-loading";
import CountUp from "react-countup";
import common_styles from "../../../styles/auditor/desktop/dashboard/common.module.css";

const ProfitComponent = ({
                             loading,
                             value,
                             displayMode,
                             onDisplayModeChange,
                             profitText = "ریال",
                             percentText = "درصد"
                         }) => {
    return (
        <div className={common_styles.half_size_statistic_container}>
            <div className={common_styles.section_header_container}>
                <IoGridOutline className={common_styles.section_icon}/>
                <span className={common_styles.section_header}>
                    مجموع سود حاصل شده
                </span>
            </div>
            {loading ? (
                <div className={common_styles.loading_container}>
                    <ReactLoading
                        type="spinningBubbles"
                        color="var(--primary-dark-color)"
                        width="50px"
                        height="50px"
                    />
                </div>
            ) : (
                <div className={common_styles.profit_container}>
                    <select
                        className={common_styles.display_mode_dropdown}
                        value={displayMode}
                        onChange={onDisplayModeChange}
                    >
                        <option value="rial">{profitText}</option>
                        <option value="percent">{percentText}</option>
                    </select>
                    <span className={common_styles.statistic_text}>
                        <CountUp end={displayMode === 'rial' ? value.profit : value.profit_pct} duration={3}/>
                        {displayMode === 'rial' ? ` ${profitText}` : ` ${percentText}`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ProfitComponent;
