import styles from "../../styles/auditor/desktop/dashboard/FinancialSection.module.css"
import common_styles from "../../styles/auditor/desktop/dashboard/common.module.css";
import {IoGridOutline} from "@react-icons/all-files/io5/IoGridOutline";
import {IoPieChartOutline} from "@react-icons/all-files/io5/IoPieChartOutline";
import ReactLoading from "react-loading";
import CountUp from "react-countup";
import React, {useRef, useState} from "react";
import useTotalProfit from "../../hooks/dashboard/overview_section/useTotalProfit";
import ProfitComponent from "./common/ProfitComponent";
import useTotalSpending from "../../hooks/dashboard/financial_section/useTotalSpending";
import useSpendingLineChart from "../../hooks/dashboard/financial_section/useSpendingLineChart";
import {MdShowChart} from "@react-icons/all-files/md/MdShowChart";
import useSpendingByProductMainCategoryPieChart
    from "../../hooks/dashboard/financial_section/useSpendingByProductMainCategoryPieChart";
import {FaTable} from "@react-icons/all-files/fa/FaTable";
import RankingTable from "../../components/RankingTable/RankingTable";
import useStoresRankingTable from "../../hooks/dashboard/overview_section/useStoresRankingTable";
import useOrganizationsRankingTable
    from "../../hooks/dashboard/financial_section/useOrganizationsRankingTable";

const FinancialSection = ({filters, role}) => {

    // profit
    const [totalProfitLoading, setTotalProfitLoading] = useState(false);
    const [profitDisplayMode, setProfitDisplayMode] = useState('rial');
    const totalProfit = useTotalProfit(filters, setTotalProfitLoading);
    const handleProfitDisplayModeChange = (e) => {
        setProfitDisplayMode(e.target.value);
    };

    // total spending value
    const [totalSpendingLoading, setTotalSpendingLoading] = useState(false);
    const totalSpending = useTotalSpending(filters, setTotalSpendingLoading);

    // spending over time chart
    const [spendingLineChartLoading, setSpendingLineChartLoading] = useState(false);
    const spendingLineChartRef = useRef(null);
    const spendingLineChartInstanceRef = useRef(null);
    useSpendingLineChart(spendingLineChartRef, spendingLineChartInstanceRef, filters, setSpendingLineChartLoading);

    // Spending distribution by product main category
    const [spendingByProductMainCategoryLoading, setSpendingByProductMainCategoryLoading] = useState(false);
    const spendingByProductPieChartRef = useRef(null);
    const spendingByProductPieInstanceRef = useRef(null);
    useSpendingByProductMainCategoryPieChart(spendingByProductPieChartRef, spendingByProductPieInstanceRef, filters, setSpendingByProductMainCategoryLoading);

    // stores ranking table
    const [topStoresLoading, setTopStoresLoading] = useState(false);
    const {
        storesRankingHeaders,
        storesRankingData
    } = useStoresRankingTable(filters, setTopStoresLoading);

    // orgs ranking table
    const [topOrganizationsLoading, setTopOrganizationsLoading] = useState(false);
    const {
        organizationsRankingHeaders,
        organizationsRankingData
    } = useOrganizationsRankingTable(filters, setTopOrganizationsLoading);

    return (
        <div className={styles.financial_section_container}>
            <div className={styles.financial_section_first_col}>
                <div className={styles.financial_section_first_row}>
                    <ProfitComponent
                        loading={totalProfitLoading}
                        value={totalProfit}
                        displayMode={profitDisplayMode}
                        onDisplayModeChange={handleProfitDisplayModeChange}
                    />

                    <div className={common_styles.half_size_statistic_container}>
                        <div className={common_styles.section_header_container}>
                            <IoGridOutline className={common_styles.section_icon}/>
                            <span className={common_styles.section_header}>
                                مجموع خریدها
                            </span>
                        </div>
                        {totalSpendingLoading ?
                            <div className={common_styles.loading_container}>
                                <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                              height='50px'/>
                            </div>
                            :
                            <span className={common_styles.statistic_text}>
                                <CountUp end={totalSpending} duration={5}/>
                                {` ریال`}
                            </span>
                        }
                    </div>
                </div>
                <div className={styles.financial_section_second_row}>
                    <div className={common_styles.trend_chart}>
                        <div className={common_styles.section_header_container}>
                            <MdShowChart className={common_styles.section_icon}/>
                            <span className={common_styles.section_header}>
                                نمودار روند تغییر مجموع خریدها در طول زمان
                            </span>
                        </div>
                        {spendingLineChartLoading ?
                            <div className={common_styles.loading_container}>
                                <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                              height='50px'/>
                            </div>
                            :
                            <div className={common_styles.trend_chart_body}>
                                <canvas ref={spendingLineChartRef}></canvas>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.financial_section_third_row}>
                    <div className={common_styles.half_size_ranking_table_container}>
                        <div className={common_styles.section_header_container}>
                            <FaTable className={common_styles.section_icon}/>
                            <span className={common_styles.section_header}>
                                فروشندگان برتر
                            </span>
                        </div>
                        {topStoresLoading ?
                            <div className={common_styles.loading_container}>
                                <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                              height='50px'/>
                            </div>
                            :
                            <div className={common_styles.ranking_table}>
                                <RankingTable
                                    headers={storesRankingHeaders}
                                    data={storesRankingData}
                                    tableTitle={"فروشندگان با بیشترین مجموع خرید"}
                                />
                            </div>
                        }
                    </div>
                    {role === "auditor" &&
                        <div className={common_styles.half_size_ranking_table_container}>
                            <div className={common_styles.section_header_container}>
                                <FaTable className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    سازمان‌های برتر
                                </span>
                            </div>
                            {topOrganizationsLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                                  height='50px'/>
                                </div>
                                :
                                <div className={common_styles.ranking_table}>
                                    <RankingTable
                                        headers={organizationsRankingHeaders}
                                        data={organizationsRankingData}
                                        tableTitle={"سازمان‌ها با بیشترین مجموع خرید"}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className={styles.financial_section_second_col}>
                <div className={common_styles.trend_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع میزان خرید براساس گروه کالا
                        </span>
                    </div>
                    {spendingByProductMainCategoryLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.pie_chart_body}>
                            <canvas ref={spendingByProductPieChartRef}></canvas>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
        ;
};

export default FinancialSection;