import styles from "../../styles/auditor/desktop/dashboard/SellersSection.module.css"
import common_styles from "../../styles/auditor/desktop/dashboard/common.module.css";
import {IoPieChartOutline} from "@react-icons/all-files/io5/IoPieChartOutline";
import ReactLoading from "react-loading";
import React, {useEffect, useRef, useState} from "react";
import useSpendingByStorePieChart from "../../hooks/dashboard/sellers_section/useSpendingByStorePieChart";
import useStoresRankingTable from "../../hooks/dashboard/overview_section/useStoresRankingTable";
import {FaTable} from "@react-icons/all-files/fa/FaTable";
import RankingTable from "../../components/RankingTable/RankingTable";
import useProductMainCategoryByStorePieChart
    from "../../hooks/dashboard/sellers_section/useProductMainCategoryByStorePieChart";
import CreatableSelect from "react-select/creatable";
import {getAllStores} from "../../APIs/store";

const SellersSection = ({filters}) => {

    // Spending distribution by store
    const [spendingByStoreLoading, setSpendingByStoreLoading] = useState(false);
    const spendingByProductPieChartRef = useRef(null);
    const spendingByProductPieInstanceRef = useRef(null);
    useSpendingByStorePieChart(spendingByProductPieChartRef, spendingByProductPieInstanceRef, filters, setSpendingByStoreLoading);

    // stores ranking table
    const [topStoresLoading, setTopStoresLoading] = useState(false);
    const {
        storesRankingHeaders,
        storesRankingData
    } = useStoresRankingTable(filters, setTopStoresLoading);

    // store spending distribution by product main category
    const [selectedStore, setSelectedStore] = useState(null);
    const [productMainCategoryByStoreLoading, setProductMainCategoryByStoreLoading] = useState(false);
    const productMainCategoryByStorePieChartRef = useRef(null);
    const productMainCategoryByStorePieInstanceRef = useRef(null);
    const isDataEmpty = useProductMainCategoryByStorePieChart(productMainCategoryByStorePieChartRef, productMainCategoryByStorePieInstanceRef,
        filters, selectedStore, setProductMainCategoryByStoreLoading);


    // stores
    const [stores, setStores] = useState([]);
    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await getAllStores();
                setStores(response.data.stores);
            } catch (error) {
                console.error('Error fetching stores:', error);
            }
        }

        fetchStores();
    }, []);

    return (
        <div className={styles.sellers_section_container}>
            <div className={styles.sellers_section_first_col}>
                <div className={styles.pie_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع میزان خرید از هر فروشنده
                        </span>
                    </div>
                    {spendingByStoreLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={styles.pie_chart_body}>
                            <canvas ref={spendingByProductPieChartRef}></canvas>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.sellers_section_second_col}>
                <div className={common_styles.full_size_ranking_table_container}>
                    <div className={common_styles.section_header_container}>
                        <FaTable className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            فروشندگان برتر
                        </span>
                    </div>
                    {topStoresLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.ranking_table}>
                            <RankingTable
                                headers={storesRankingHeaders}
                                data={storesRankingData}
                                tableTitle={"فروشندگان با بیشترین مجموع خرید"}
                            />
                        </div>
                    }
                </div>
                <div className={styles.pie_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع گروه‌های کالایی خریداری شده از هر فروشنده
                        </span>
                    </div>
                    <div className={styles.seller_select_container}>
                        <span className={common_styles.filter_select_subject}>فروشنده:</span>
                        <div className={common_styles.filter_select_container}>
                            <CreatableSelect
                                className={common_styles.filters_select}
                                value={{
                                    label: selectedStore === null ? "انتخاب کنید" : selectedStore,
                                    value: selectedStore === null ? "انتخاب کنید" : selectedStore
                                }}
                                onChange={(selectedOption) => setSelectedStore(selectedOption ? selectedOption.value : '')}
                                options={stores.map((store) => ({
                                    label: store.name,
                                    value: store.name,
                                }))}
                                isClearable={false}
                            />
                        </div>
                    </div>
                    {productMainCategoryByStoreLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        selectedStore === null ?
                            <span style={{alignSelf: 'center', paddingTop: '4vw', color: 'var(--primary-dark-color)'}}>برای مشاهده این قسمت لازم است ابتدا یک فروشگاه را انتخاب کنید.</span>
                            :
                            isDataEmpty ?
                                <span style={{alignSelf: 'center', paddingTop: '4vw', color: 'var(--primary-dark-color)'}}>داده‌ای برای فروشگاه انتخاب شده وجود ندارد.</span>
                                :
                                <div className={styles.horizontal_pie_chart_body}>
                                    <canvas ref={productMainCategoryByStorePieChartRef}></canvas>
                                </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default SellersSection;